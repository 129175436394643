var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-add-role","scrollable":"","cancel-variant":"outline-secondary","ok-variant":"primary","hide-footer":!_vm.$can('update','role') || !_vm.$can('create','role'),"centered":""},on:{"hidden":function($event){return _vm.resetModal()}},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.content.title))]),_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":function($event){return close()}}},[_vm._v("x")])]}},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button"},on:{"click":function($event){return cancel()}}},[_vm._v(_vm._s(_vm.$t('Close')))]),_c('button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btn btn-primary",attrs:{"type":"button","disabled":_vm.show},on:{"click":_vm.confirmAddRole}},[_vm._v(" "+_vm._s(_vm.content.ok)+" ")])]}}])},[_c('b-overlay',{attrs:{"show":_vm.show,"variant":"transparent","no-wrap":""}}),_c('validation-observer',{ref:"addRole"},[_c('b-form',{ref:"",staticClass:"auth-reset-password-form",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationFormRole.apply(null, arguments)}}},[_c('b-tabs',{attrs:{"content-class":"mt-2"}},[_c('b-tab',{attrs:{"active":"","title":_vm.$t('Role info'),"lazy":""}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Name')))]),_c('validation-provider',{attrs:{"name":"roleName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.roleData.name),callback:function ($$v) {_vm.$set(_vm.roleData, "name", $$v)},expression:"roleData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"name"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Department')))]),_c('validation-provider',{attrs:{"name":"department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.membersDepartments,"reduce":function (val) { return val.id; },"label":"name","input-id":"department"},model:{value:(_vm.roleData.departmentId),callback:function ($$v) {_vm.$set(_vm.roleData, "departmentId", $$v)},expression:"roleData.departmentId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"active"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Status')))]),_c('validation-provider',{attrs:{"name":"active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"label":"label","input-id":"department"},model:{value:(_vm.roleData.active),callback:function ($$v) {_vm.$set(_vm.roleData, "active", $$v)},expression:"roleData.active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"description"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Description')))]),_c('b-form-input',{attrs:{"id":"description","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.roleData.description),callback:function ($$v) {_vm.$set(_vm.roleData, "description", $$v)},expression:"roleData.description"}})],1)],1),_c('b-tab',{attrs:{"title":_vm.$t('Functions assignment'),"lazy":""}},[_c('v-jstree',{attrs:{"data":_vm.roleFunctions,"allow-transition":false,"show-checkbox":"","multiple":"","allow-batch":"","whole-row":"","text-field-name":"name","value-field-name":"action","children-field-name":"manager_functions"},on:{"item-click":_vm.itemClick}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }