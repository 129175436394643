<template>
	<div>
		<b-modal
			id="modal-add-role"
			scrollable
			cancel-variant="outline-secondary"
			ok-variant="primary"
			:hide-footer="!$can('update','role') || !$can('create','role')"
			centered
			@hidden="resetModal()"
		>
			<b-overlay
				:show="show"
				variant="transparent"
				no-wrap
			/>
			<template #modal-header="{ close }">
				<h5 class="modal-title">{{ content.title }}</h5>
				<button type="button" class="close" @click="close()">x</button>
			</template>

			<template #modal-footer="{ cancel}">
				<button type="button" class="btn btn-outline-secondary" @click="cancel()">{{ $t('Close') }}</button>
				<button
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					type="button"
					class="btn btn-primary"
					:disabled="show"
					@click="confirmAddRole"
				>
					{{ content.ok }}
				</button>
			</template>
			<!-- form -->
			<validation-observer ref="addRole">
				<b-form
					ref=""
					method="POST"
					class="auth-reset-password-form"
					@submit.prevent="validationFormRole"
				>
					<b-tabs
						content-class="mt-2"
					>
						<!-- Tab: Role Information -->
						<b-tab
							active
							:title="$t('Role info')"
							lazy
						>
							<!-- Field: Name -->
							<b-form-group
								label-for="name"
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Name') }}</label>
								<validation-provider
									#default="{ errors }"
									name="roleName"
									rules="required"
								>
									<b-form-input
										id="name"
										v-model="roleData.name"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- Field: Department -->
							<b-form-group
								label-for="name"
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Department') }}</label>
								<validation-provider
									#default="{ errors }"
									name="department"
									rules="required"
								>
									<v-select
										v-model="roleData.departmentId"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="membersDepartments"
										:reduce="val => val.id"
										label="name"
										input-id="department"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- Field: Active -->
							<b-form-group
								label-for="active"
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Status') }}</label>
								<validation-provider
									#default="{ errors }"
									name="active"
									rules="required"
								>
									<v-select
										v-model="roleData.active"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="statusOptions"
										:reduce="val => val.value"
										label="label"
										input-id="department"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- Description -->
							<b-form-group
								label-for="description"
							>
								<label class="mb-1">{{ $t('Description') }}</label>
								<b-form-input
									id="description"
									v-model="roleData.description"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								/>

							</b-form-group>

						</b-tab>
						<!-- Tab: Functions -->
						<b-tab
							:title="$t('Functions assignment')"
							lazy
						>
							<v-jstree
								:data="roleFunctions"
								:allow-transition="false"
								show-checkbox
								multiple
								allow-batch
								whole-row
								text-field-name="name"
								value-field-name="action"
								children-field-name="manager_functions"
								@item-click="itemClick"
							/>
						</b-tab>
					</b-tabs>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BOverlay, BTab, BTabs, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import roleStoreModule from '@/views/local-setting/local/roleStoreModule'
import memberStoreModule from '../memberStoreModule'
import VJstree from 'vue-jstree'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
	components: {
		BTab, BTabs, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		BOverlay,
		vSelect,
		VJstree,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		membersDepartments: {
			type: Array,
			required: true,
			default: null,
		},
		statusOptions: {
			type: Array,
			required: true,
			default: null,
		},
		content: {
			type: Object,
			required: false,
			default: null,
		},
		roleId: {
			type: Number,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			roleData: {
				name: '',
				departmentId: '',
				active: 2,
				description: null,
				functionIds: [],
			},
			api: null,
			data: [
				{
					text: 'Member Management',
					children: [
						{
							text: 'initially selected',
							id: 36,
							value: 'User View',
							selected: true
						},
						{
							text: 'custom icon',
						},
						{
							text: 'initially open',
							opened: true,
							children: [
								{
									text: 'Another node'
								}
							]
						},
						{
							text: 'custom icon',
						},
						{
							text: 'disabled node',
							disabled: true
						}
					]
				},
			],
			show: false,
		}
	},
	computed() {
	},
	watch: {
		async roleId(newVal) {
			if (newVal) {
				this.show = true
				await this.getRoleData(newVal)
				this.show = false
			}
		},
	},
	mounted() {
	},
	created() {
	},
	destroyed() {
	},
	methods: {
		itemClick(node) {
			if (node.model.selected && !this.roleData.functionIds.includes(node.model.id)) {
				if (node.model.manager_functions) {
					node.model.manager_functions.map(item => {
						if (!this.roleData.functionIds.includes(item.id)) {
							this.roleData.functionIds.push(item.id)
						}
						if (item.manager_functions) {
							item.manager_functions.map(item1 => {
								if (!this.roleData.functionIds.includes(item1.id)) {
									this.roleData.functionIds.push(item1.id)
								}
							})
						}
					})
				}
				this.roleData.functionIds.push(node.model.id)
			} else {
				this.removeFunction(node.model.id, this.roleData.functionIds)
				if (node.model.manager_functions) {
					node.model.manager_functions.map(item => {
						this.removeFunction(item.id, this.roleData.functionIds)
						if (item.manager_functions) {
							item.manager_functions.map(item1 => {
								this.removeFunction(item1.id, this.roleData.functionIds)
							})
						}
					})
				}
			}
		},
		itemClickAddParentOnly(node) {
			// console.log(`${node.model.selected} clicked !`)
			if (node.model.manager_functions) {
				node.model.manager_functions.map(item => {
					this.removeFunction(item.id, this.roleData.functionIds)
				})
			}
			if (node.model.selected && !this.roleData.functionIds.includes(node.model.id)) {
				/* if (node.model.manager_functions) {
					node.model.manager_functions.map(item => {
						if (!this.roleData.functionIds.includes(item.id)) {
							this.roleData.functionIds.push(item.id)
						}
					})
				} */
				this.roleData.functionIds.push(node.model.id)
			} else {
				if (typeof node.$parent.model === 'undefined') {
					// console.log('k có cha')
				} else {
					// console.log(node.parentItem)
					this.removeFunction(node.$parent.model.id, this.roleData.functionIds)
					node.$parent.model.selected = false
					node.parentItem.map(item => {
						if (item.selected && !this.roleData.functionIds.includes(item.id)) {
							this.roleData.functionIds.push(item.id)
						}
					})
				}
				this.removeFunction(node.model.id, this.roleData.functionIds)
			}
		},
		removeFunction(id, array) {
			const index = array.indexOf(id)
			if (index > -1) {
				array.splice(index, 1)
			}
		},
		validationFormRole() {
			this.$refs.addRole.validate().then(success => {
				if (success) {
					if (this.roleId) {
						this.api = 'local-setting-role/updateRole'
					} else {
						this.api = 'local-setting-role/addRole'
					}
					store.dispatch(this.api, this.roleData)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code === '00') {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$emit('refetch-data')
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
								this.$nextTick(() => {
									setTimeout(() => {
										this.$bvModal.hide('modal-add-role')
									}, '500')
									setTimeout(() => {
										this.resetModal()
									}, '1000')
								})
							}
						})
						.catch(error => {
							const { config, response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		resetRole() {
			this.roleFunctions.map(item => {
				if (typeof item.selected !== 'undefined') {
					item.selected = false
				}
				if (item.manager_functions) {
					item.manager_functions.map(children => {
						if (typeof children.selected !== 'undefined') children.selected = false
						if (typeof item.opened !== 'undefined') item.opened = false

						if (children.manager_functions) {
							children.manager_functions.map(childrenChildren => {
								if (typeof childrenChildren.selected !== 'undefined') childrenChildren.selected = false
								if (typeof children.opened !== 'undefined') children.opened = false
							})
						}
					})
				}
			})
		},
		async getRoleData(id) {
			// this.resetRole()
			await this.$store
				.dispatch('local-setting-role/fetchRoleDetail', id)
				.then(response => {
					const res = response.data.data
					/* const test = res.manager_functions.map(item => ({
						...item, selected: true
					})) */
					// const arr3 = this.roleFunctions.map((item, i) => Object.assign({}, item, test[i]))
					// console.log(arr3)
					// console.log(test)

					const test = res.manager_functions.map(item => item.id)
					this.roleFunctions.map((item, i) => {
						if (test.includes(item.id)) {
							Object.assign(item, { selected: true })
							/* if (item.manager_functions) {
								item.manager_functions.map(children => {
									Object.assign(children, { selected: true })
								})
							} */
						}
						if (item.manager_functions) {
							item.manager_functions.map(children => {
								if (test.includes(children.id)) {
									Object.assign(children, { selected: true })
								}
								if (children.manager_functions) {
									children.manager_functions.map(children2 => {
										if (test.includes(children2.id)) {
											Object.assign(children2, { selected: true })
										}
									})
								}
							})
						}
					})
					this.roleData.roleId = id
					this.roleData.name = res.name
					this.roleData.active = res.status
					this.roleData.departmentId = res.department_id
					this.roleData.description = res.description
					this.roleData.functionIds = test
				})
		},
		resetModal() {
			this.$emit('update:roleId', null)
			this.roleData.roleId = null
			this.roleData.name = ''
			this.roleData.active = 2
			this.roleData.departmentId = ''
			this.roleData.description = null
			this.roleData.functionIds = []
			this.show = false
			this.resetRole()
		},
		confirmAddRole(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormRole()
		},
		logout() {
			localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
			localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
			localStorage.removeItem('userData')
			localStorage.removeItem('token')
			localStorage.removeItem('userFunctions')
			// Reset ability
			this.$ability.update(initialAbility)
			this.$router.push({ name: 'auth-login' })
		},
	},
	setup() {
		if (!store.hasModule('local-setting-role')) store.registerModule('local-setting-role', roleStoreModule)
		if (!store.hasModule('app-member')) store.registerModule('app-member', memberStoreModule)
		const roleFunctions = ref(null)
		store
			.dispatch('local-setting-role/fetchFunctions')
			.then(response => {
				roleFunctions.value = response.data
			})
		return {
			roleFunctions,
		}
	},
}
</script>
<style>
	.tree-default .tree-themeicon {
		display: none !important;
	}
	.tree-default>.tree-container-ul>.tree-node {
		margin-bottom: 6px;
	}
	.tree-wholerow-ul {
		margin-bottom: 0 !important;
	}
	.modal-dialog-centered.modal-dialog-scrollable .modal-content {
		max-height: 100% !important;
	}
</style>