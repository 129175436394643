<template>
	<div>
		<!-- Filter -->
		<b-card
			no-body
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<b-col
						cols="12"
						md="3"
					>
						<b-form-group
							:label="$t('Role name')"
							label-for="role-name"
						>
							<b-form-input
								id="role-name"
								v-model="nameFilter"
								class="form-control"
								placeholder="Please Enter Role Name"
							/>
						</b-form-group>
					</b-col>
					<b-col
						cols="12"
						md="3"
					>
						<b-form-group
							:label="$t('Department')"
							label-for="department"
						>
							<v-select
								v-model="departmentFilter"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="membersDepartments"
								:reduce="val => val.id"
								label="name"
								input-id="department"
							/>
						</b-form-group>
					</b-col>
					<b-col
						cols="12"
						md="3"
					>
						<b-form-group
							:label="$t('Status')"
							label-for="status"
						>
							<v-select
								v-model="statusFilter"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="statusOptions"
								:reduce="val => val.value"
								input-id="status"
							/>
						</b-form-group>
					</b-col>
					<b-col
						cols="12"
						md="3"
						class="d-flex align-items-center justify-content-end"
					>
						<b-button
							variant="outline-secondary"
							@click="resetFilter()"
						>
							{{ $t('Reset') }}
						</b-button>
						<b-button
							variant="primary"
							class="ml-2"
							@click="searchFilter()"
						>
							{{ $t('Search') }}
						</b-button>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<!-- List -->
		<b-card
			no-body
		>
			<div
				v-if="$can('create', 'role')"
				class="m-2"
			>

				<!-- Table Top -->
				<b-row>
					<!-- Search -->
					<b-col
						cols="12"
					>
						<div class="d-flex align-items-center justify-content-end">
							<b-button
								variant="primary"
								@click="showAddRoleModal()"
							>
								<span class="text-nowrap">
									<feather-icon icon="PlusIcon"/> {{ $t('New') }}
								</span>
							</b-button>
						</div>
					</b-col>
				</b-row>

			</div>
			<b-table
				ref="refMembersRoleListTable"
				class="position-relative table-white-space mb-0"
				:items="membersRoles"
				responsive
				:fields="tableColumns"
				primary-key="index"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

				<template #cell(name)="data">
					<b-link
						class="text-primary"
						@click="showAddRoleModal(data.item.id)"
					>
						{{ data.item.name }}
					</b-link>
				</template>

				<template #cell(department)="data">
					{{ data.item.department ? data.item.department.name : null }}
				</template>

				<template #cell(status)="data">
					<div :class="`text-capitalize align-items-center d-flex`">
						<span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{ resolveStatus(data.item.status).label }}
					</div>
				</template>

				<template #cell(createdAt)="data">
					{{ formatDateTime(data.item.created_at ? data.item.created_at : '').replace('.000000Z', '') }}
				</template>

				<template #cell(updatedAt)="data">
					{{ formatDateTime(data.item.updated_at ? data.item.updated_at : '').replace('.000000Z', '') }}
				</template>

				<template #cell(action)="data">
					<b-link
						v-if="$can('read', 'roledetail')"
						class="font-weight-bold text-nowrap"
						@click="showAddRoleModal(data.item.id)"
					>
						{{ $t('Edit') }}
					</b-link>
				</template>

			</b-table>
			<div class="p-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>

						<b-pagination
							v-model="currentPage"
							:total-rows="totalMembersRoles"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

		<!-- Modal: Add Role -->
		<add-role-modal
			:members-departments="membersDepartments"
			:status-options="statusOptions"
			:role-id.sync="roleId"
			:content.sync="content"
			@refetch-data="refetchData"
		/>
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import { formatDateTime } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import roleStoreModule from '@/views/local-setting/local/roleStoreModule'
import memberStoreModule from '@/views/apps/member/memberStoreModule'
import AddRoleModal from './AddRoleModal.vue'
import i18n from '@/libs/i18n'

export default {
	components: {
		AddRoleModal,
		BButton,
		BFormGroup,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			roleId: null,
			content: null,
		}
	},
	methods: {
		showAddRoleModal(id = null) {
			if (id) {
				this.roleId = id
				this.content = {
					type: 2,
					title: i18n.t('Edit role'),
					ok: i18n.t('Save'),
				}
			} else {
				this.roleId = null
				this.content = {
					type: 1,
					title: i18n.t('Add role'),
					ok: i18n.t('Add'),
				}
			}
			this.$bvModal.show('modal-add-role')
		},
	},
	setup() {
		if (!store.hasModule('local-setting-role')) store.registerModule('local-setting-role', roleStoreModule)
		if (!store.hasModule('app-member')) store.registerModule('app-member', memberStoreModule)

		const toast = useToast()

		const membersDepartments = ref([])
		const departmentFilter = ref(null)
		const statusFilter = ref(null)
		const nameFilter = ref(null)

		const perPage = ref(25)
		const totalMembersRoles = ref(0)
		const currentPage = ref(1)
		const perPageOptions = [10, 25, 50, 100]
		const sortBy = ref('id')
		const isSortDirDesc = ref(true)

		const refMembersRoleListTable = ref(null)

		const tableColumns = [
			{ key: 'index', label: '#' },
			{ key: 'name', label: 'Name' },
			{ key: 'department', label: i18n.t('Department') },
			{ key: 'description', label: i18n.t('Description') },
			{ key: 'status', label: i18n.t('Status') },
			{ key: 'createdAt', label: i18n.t('Created at') },
			{ key: 'updatedAt', label: i18n.t('Updated at') },
			{ key: 'action', label: i18n.t('Actions') }
		]

		const dataMeta = computed(() => {
			const localItemsCount = refMembersRoleListTable.value ? refMembersRoleListTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalMembersRoles.value,
			}
		})

		const refetchData = () => {
			refMembersRoleListTable.value.refresh()
		}

		watch([currentPage, perPage, nameFilter, departmentFilter, statusFilter], () => {
			refetchData()
		})

		const membersRoles = (ctx, callback) => {
			store.dispatch('local-setting-role/fetchRoleLists', {
				name: nameFilter.value,
				departmentId: departmentFilter.value,
				status: statusFilter.value,
				page: currentPage.value,
				perPage: perPage.value,
			})
				.then(response => {
					const { roles, total } = response
					callback(roles)
					totalMembersRoles.value = total
				})
		}

		store
			.dispatch('app-member/fetchDepartments', {
				// perPage: perPage.value + 50,
				// page: currentPage.value,
				// name: null,
			})
			.then(response => {
				const { departments } = response
				membersDepartments.value = departments
			})

		const statusOptions = [
			{ label: i18n.t('Active'), value: 1 },
			{ label: i18n.t('Inactive'), value: 2 },
		]

		const resolveStatus = status => {
			if (status == 1) return { label: i18n.t('Active'), variant: 'primary' }
			if (status == 2) return { label: i18n.t('Inactive'), variant: 'danger' }
			return { label: 'Pending', variant: 'secondary' }
		}

		const resetFilter = () => {
			nameFilter.value = ''
			departmentFilter.value = ''
			statusFilter.value = ''
		}

		return {
			tableColumns,
			perPage,
			currentPage,
			totalMembersRoles,
			dataMeta,
			perPageOptions,
			sortBy,
			isSortDirDesc,
			refMembersRoleListTable,

			membersRoles,
			membersDepartments,
			refetchData,
			nameFilter,

			resetFilter,
			departmentFilter,
			statusFilter,

			resolveStatus,
			statusOptions,

			formatDateTime,
		}
	},
}
</script>